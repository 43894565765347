<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        环境管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增环境</el-button>
      </div>
       <!-- 当前接口信息展示 -->
       <el-table :data="tableDataEnv" style="width: 100%">
        <el-table-column
          prop="env_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="hub_url"
          label="selenium hub地址">
        </el-table-column>
        <el-table-column
          prop="browser_type"
          label="浏览器类型">
        </el-table-column>
        <el-table-column
          prop="browser_version"
          label="浏览器版本">
        </el-table-column>
        <el-table-column
          prop="maxInstances"
          label="节点并发数">
        </el-table-column>
        <el-table-column
          prop="options"
          label="浏览器配置">
        </el-table-column>
        <el-table-column
          prop="description"
          label="环境描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="环境状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleEnv"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormEnv" :rules="FormRulesEnv" ref="ruleFormRefEnv" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="环境ID">
          <el-input v-model="ruleFormEnv.env_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="服务URL" prop="page_name">
          <el-input v-model="ruleFormEnv.hub_url"></el-input>
        </el-form-item>
        <el-form-item label="浏览器类型" prop="page_name">
          <el-input v-model="ruleFormEnv.browser_type"></el-input>
        </el-form-item>
        <el-form-item label="浏览器版本" prop="page_name">
          <el-input v-model="ruleFormEnv.browser_version"></el-input>
        </el-form-item>
        <el-form-item label="节点并发" prop="page_name">
          <el-input v-model="ruleFormEnv.maxInstances"></el-input>
        </el-form-item>
        <el-form-item label="浏览器配置" prop="page_name">
          <el-input v-model="ruleFormEnv.options"></el-input>
        </el-form-item>
        <el-form-item label="页面状态" prop="page_name">
          <el-switch v-model="ruleFormEnv.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="页面描述" prop="description">
          <el-input type="textarea" v-model="ruleFormEnv.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEnv = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAddEnv">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEditEnv">编 辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryEnvList, addEnv, editEnv, deleteEnv } from '@/services/uiEnv.js'
export default {
  name: 'UiEnv',
  data () {
    return {
      isAdd: true,
      tableDataEnv: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      dialogVisibleEnv: false,
      ruleFormEnv: {},
      FormRulesEnv: {
        env_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadGetEnvList()
  },
  methods: {
    async loadGetEnvList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryEnvList(queryObj)
      if (res.status === 200) {
        this.tableDataEnv = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetEnvList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetEnvList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      console.log('新增动作')
      this.isAdd = true
      this.ruleFormEnv = {}
      this.dialogVisibleEnv = true
    },
    handleEdit (rowData) {
      console.log('编辑动作')
      this.isAdd = false
      this.ruleFormEnv = rowData
      this.dialogVisibleEnv = true
    },
    handleDel (rowData) {
      console.log('删除动作')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { env_id: rowData.env_id }
        const { data: res } = await deleteEnv(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除用例成功')
          this.loadGetEnvList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAddEnv () {
      const addObj = this.ruleFormEnv
      const { data: res } = await addEnv(addObj)
      if (res.status === 200) {
        this.dialogVisibleEnv = false
        this.$message.success('添加成功')
        this.loadGetEnvList()
      }
    },
    async processEditEnv () {
      const editObj = this.ruleFormEnv
      const { data: res } = await editEnv(editObj)
      if (res.status === 200) {
        this.dialogVisibleEnv = false
        this.$message.success('添加成功')
        this.loadGetEnvList()
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefEnv.resetFields()
      this.loadGetEnvList()
    }
  }
}
</script>

<style>

</style>
